

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
*{
  padding: 0;
  margin: 0;
}
body{
  font-family: 'Poppins';
  line-height: 1.5;
}
h1,h2{
  font-family: 'Oswald', sans-serif;
}
h3,h4,h5,h6,p,footer{
  font-family: 'Poppins';
  line-height: 1.5;
}


a{
  text-decoration: none;
}
a:hover{
  text-decoration: none;
}
.header-1{
  font-size: 48px;
  font-weight: 600;
}
.header-2{
  font-size: 38px;
  font-weight: 600;
}
.header-2 span{
  font-weight: normal;
}

.capability_page img{
  border: 6px solid black;
  
  width: 80vw;
 
}

#home_page{
  padding-top: 90px;
}
#home_page ._hero-area .header-2{
  font-weight: 500 !important;
  font-size: 35px;
}
#home_page ._hero-area .hero_description p{
  font-size: 14px !important;
  width: 35%;
  line-height: 2.2;
  font-weight: 500;
  text-shadow: #fff;
}
#home_page ._hero-area{
  height: 100vh;
  width: 100%;
  background: url('../images/background/home_hero_bg.webp') no-repeat center;
  padding-top: 2rem;
  background-size: cover; 
  background-size: 100% 100%;
}
#home_page  .hero-area{
  color: #fff;
  padding-top: 60px;
  position: absolute;
  top: 120px;
  left: 0;
  line-height: 2;
}

#home_page  .hero-area h1{
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 35px;
}
.hero-area .description{
  font-size: 14px;
  margin-bottom: 55px;
  font-weight: 100;
  padding: 0 4rem;
}
._hero-area .hero-area h2{
  line-height: 1.5;
}
.hero-area .end_text{
  font-size: 28px;
}
.hero-area .end_text span{
  height: 15px;
  border: 2px solid #fff;
  padding: 0;
  font-size: 14px !important;
  margin-top: -15px !important;
}
.end_text{
  justify-content: center;
}
.end_text .line{
  margin: 0 7px;
  margin-top: 5px;
  border-left: 3px solid #fff;
  height: 30px;
  
}

.industries_sec{
  width: 100%;
  background: url('../images/background/industreis-bg.webp') no-repeat center;
  padding-top: 2rem;
  background-size: cover;  
}
.industries_sec .row{
  color: #3d3d3d;
}
.industries_sec .title {
  text-align: center;
}
.industries_sec .title h1{
  display: block;
  
}
.industries_sec .title span{
  font-weight: bold;
}

.industries_card:hover a{
  text-decoration: none;
}
.industries_line{
  margin: auto;
  border: 5px solid #e1e1e1;
  width: 100px;
  margin-bottom: .5rem;
  margin-top: 10px;
}
.industries_sec .end_text{
  padding: 2rem 0;
}
.industries_sec .end_text p{
  font-size: 16px;
  text-align: center;
}
.peruse_sec{
  background: url('../images/background/Screen.webp');  
  position: relative;  
  height: 40rem;
  z-index: 1;
}
.peruse_sec .particals{
  height: 100%;
  display: flex;
}
.peruse_sec .particals_phone{
  height: 100%;
  display: none;
}
.partical_card_d-none{
  display: none;
}
.peruse_sec .content-sec{
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
}
.peruse_sec .single_content{
  opacity: .8;
  padding: 50px 40px;
  height: fit-content;
  box-shadow: 1px 5px 20px #000;
}
.peruse_sec .single_content p{
  text-align: justify;
}
.peruse_sec .left{
  background: #1a99b2;
  color: #fff;
}
.peruse_sec .right{
  background: #fff;
  color: #000;
  margin-top: 10rem;
}
.content-sec .line{
  width: 150px;
  border: 3px solid #fff;
  margin-left: -40px;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 30px;
}
.content-sec .right .line{
  width: 150px;
  border: 3px solid #000;
  margin-left: -40px;
  padding: 0;
}
.peruse_sec .title h1{
  font-size: 35px;
}

/* portfolio sec  */
.portfolio_sec{
  width: 100%;
  height: 100%;
  background: url('../images/background/product-portfolio-bg.webp') no-repeat center;
  padding-top: 2rem;
  background-size: cover; 
}
.portfolio_sec a{
  color: #3d3d3d;
}
.portfollio_green_arrow{
  display: none;
}
.portfolio_sec a:hover p{
  color: #093c1f;
}
.portfolio_sec a:hover .portfollio_green_arrow{
  display: inline;
}
.portfolio_sec a:hover .portfollio_black_arrow{
  display: none;
  }
.portfolio_sec .description img{
  width: 8px;
  margin-right: 5px;
}

/* ai-section */
.ai_section{
  width: 100%;
  background: url('../images/background/ai-body-bg.webp') no-repeat center;
  padding-top: 5rem;
  background-size: cover; 
  color: #fff;
}
.ai_section img{
  width: 90%;
  height: auto;
}

.client_section{
  width: 100%;
  background: url('../images/background/client1-bg.webp') no-repeat center;
  padding-top: 2rem;
  background-size: cover;  
  color: #3d3d3d;
}
.title h1 span{
  font-weight: normal;
}
.client_section .title p{
  font-size: 14px;
}

.affiliation{    
  width: 100%;
  padding: 2rem 0;
  background: #f1f1f1;
}

.logo_sec{
  padding: 0 2rem;
  padding-top: 20px;
}
.logo_sec .single_item{
  text-align: center;
  color: #3d3d3d;
}
.logo_sec .single_item img{
  width: 120px;
  height: 120px;
  margin-bottom: 25px;
} 

.logo_sec .affiliation1 img{
  margin-top: 10px;
  width: 120px !important;
  height: 110px !important;
}
.logo_sec .affiliation5 img{
  margin-top: 30px;
  width: 125px !important;
  height: 65px !important;
}
.logo_sec .affiliation5 p{
  padding-top: 25px;
}
.logo_sec .affiliation6 img{
  margin-top: 30px;
  width: 120px !important;
  height: 65px !important;
}
.logo_sec .affiliation6 p{
  padding-top: 25px;
}
.menu_padding{
  padding-top: 90px;
}




/* about page  */

.about_page .hero_area{
  width: 100%;
  height: 100%;
  background: url('../images/about/about-hero.webp') ;
  color: #fff;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.about_page .description b{

font-weight: bold !important;

}


.hero_title .title{
  padding-top: 110px;
  padding-bottom: 110px;
  margin: auto;
}
.training .hero_title .title{
  text-shadow: #1a99b2;
  padding-top: 110px;
  padding-bottom: 110px;
  
  margin: auto;
}
.partners_sec .hero_title .title{
  padding-top: 30px;
  padding-bottom: 110px;
  margin: auto;
}
.hero_title .title h1 span{
  font-weight: 100 !important;
}
.hero_title .title .hero_line{
  width: 80px;
  border: 2px solid #fff;
  margin-left: 4.5rem;
}

.body_section{
  
  padding: 2rem 0;
  color: #3d3d3d;
}
.body_section .title h1{  
  text-align:justify
}
.body_section ._line{
  margin-top: 8px;
  width: 60px;
  border: 2px solid #dfe9f2;
}
.body_section .description p{
  text-align:justify;
  margin-bottom: 40px;
  margin-top: 10px;
}
.body_section .img_sec{
  width: 100%;
  text-align: center;
  padding-bottom: 40px;
}
.body_section .img_sec img{
  width: 90%;
}


/* partners */
.partners_sec{
  width: 100%;
  background: url('../images/partners/partners-bg.webp') ;
  color: #fff;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-bottom: 10rem;
}

.partners_sec .title{
  padding-bottom: 0;
}
.partners_sec .hero_title .hero_line{
  margin-left: 8rem;
}
.partner_double_loge{
  padding-right: 40px;
}
.partner_logo .aws{
  width: 120px;
  height: auto;
}
.partner_logo .micro{
  margin-top: 10px;
  width: 200px;
  height: auto;
  margin-left: 82px;
}
.partner_logo .ampex{
  width: 220px;
  height: auto;
}
.partner_logo .binary{
  width: 125px;
  height: auto;
  margin-left: -10px;
}
.partner_logo .ibm{
  width: 200px;
  height: auto;
}
.partner_logo .partnerLogo{
  width: 85px;
  height: auto;
}


/* contact Us Page  */
.contact_sec{
  width: 100%;
  background: url('../images/background/contact_hero_bg.webp') ;
  color: #fff;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.cantact_page .body_section{
  width: 100%;
  background: url('../images/background/industreis-bg.webp') ;
  color: #fff;
  background-repeat: no-repeat;
  padding-top: 3rem;
  background-size: 100% 100%;
}
.cantact_page .body_section{
  color: #3d3d3d;
}
.cantact_page .left .top h3{
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 15px;
}
.cantact_page .left  p{
  font-size: 15px;
}
.cantact_page .left .top p span{
  font-weight: bold;
}
.cantact_page .left .bottom{
  padding-top: 1rem;
}
.bottom {
  display: flex;
  flex-direction: row !important;
}
.bottom .address:not(:last-child){
  padding-bottom: 20px;
}
.bottom .address h4{
  color: #125c87;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
}
.bottom .address ._img{
  height: 30px;
  width: 30px;
  background: #125c87;
  border-radius: 50%;
  margin-right: 10px;
}
.bottom .address:not(:last-child){
  margin-right: 10px;
}
.bottom .address ._img img{
  margin:6px;
  height: 15px;
  width: 18px;
}
.phone_icon img{
  margin: 7px !important;
}
.bottom .address p{
  font-size: 12px;
}
.contact__map{
  width: 100%;
  height: 27.7rem;
}
.cantact_page .right{
  background: #fff;
  padding-top: 2.6rem;
  padding-left: 1.8rem;
}
.cantact_page .right{
  height: fit-content;
  padding-bottom: 30px;
}
.cantact_page .right h2{
  font-size: 22px;
  font-weight: 600;
}
.h2_line {
  width: 30px;
  border: 2px solid #d9d9d9;
  margin-top: 5px;
  margin-bottom: 2rem;
}
.cantact_page .right input, 
.cantact_page .right textarea{
  width: 90%;
  border: none;
  padding: 10px;
  border-bottom: 2px solid #125c87;
  margin-bottom: 1.3rem;
  outline: none;
}
.cantact_page .submit-btn{
  margin-top: 1.5rem;
  width: 130px !important;
  height: 40px;
  border: 2px solid transparent;
  background: #125c87;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  transition: .3s ease-in-out;
  cursor: pointer;
}
.cantact_page .submit-btn:hover{
  border: 2px solid #125c87;
  background: #fff;
  color: #125c87;
}

/* news and events page  */
.texture_bg{
  width: 100%;
  background: url('../images/background/industreis-bg.webp') ;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.white_texture_bg{
  width: 100%;
  background: url('../images/background/white-texture-bg.webp') ;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}







/* career page css */

.career_page .hero_area{
  width: 100%;
  background: url('../images/background/career-hero-bg.webp') ;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #fff;
}
.career_page .hero_title hr{
  margin-left: 4rem;
}
.career_page .body_section{
  padding: 0;
}
.career_page .body_section{
  width: 100%;
  background: url('../images/background/industreis-bg.webp') ;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #3d3d3d;
}

.career_page .body_img_bg{
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 30px 0;
  margin-bottom: 20px;
}
.career_page .bullet_title{
  font-size: 20px;
}
.career_page .description{
  text-align: justify;
  font-size: 20px !important;
}
.bullet_title{
  font-size: 20px;
  margin-bottom: 30px;
}
.bullet{
  line-height: 1;
}
.bullet img{
  height: 20px;
  margin-right: 15px;
}
.body_color_bg{
  background: #f0f0f0;
  padding: 30px 0;
  margin-bottom: 1rem;
}
.belief_text p{
  font-size: 24px;
}


.filter_area .row{
  padding: 0 1rem;
}
.filter_area label,
.filter_area select,
.filter_area input{
  width: 100%;
  outline: none;
}
.filter_area select,
.filter_area input{
  background: transparent;
  padding: 5px 10px;
  border: 1px solid #d3d3d3;
}
.filter_area .search_btn{
  position: absolute;
  border: none;
  background: transparent;
  margin-left: -2rem;
  width: 20px;
  height: auto;
  margin-top: 3px;
}
.filter_area .search_btn img{
  width: 15px;
  height: auto;
}

.filter_area select option{
  background: #2c81b9;
  color: #fff;
}

.card_bottom{
  color: #fff;
  padding-bottom: 15px;
}
.card_bottom p{
  font-size: 13px;
}
.card_bottom .description{
  margin-bottom: 20px !important;
  padding: 0 5px;
}
.card_bottom h2{
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 500;
}
.card_bottom a{
  font-size: 10px;
  text-transform: uppercase;
  padding: 5px .5rem;
  background: #fff;
  border-radius: 30px;
  color: #2c81b9;
  font-weight: 600;
  border: 2px solid transparent;
  transition: .3s ease-in-out;
}
.card_bottom a:hover{
  border: 2px solid #fff;
  background: #0b4b24;
  color: #fff !important;
}
@media screen and (max-width: 992px) {
  
}



/* use case page  */
#usecase_page_section .body_section{
  padding-top: 0 !important;
}



.capability_page .hero_area{
  background: url('../images/background/capability-statement-hero-bg.webp') ;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #fff;
}
.contact_btn{
  margin-top: 2.5rem;
}
.pdf_display iframe{
  width: 88%;
  height: 800px;
  background: #fff;
  font-size: 10px;
}


/* services page  */
.services_page .hero_area{
  width: 100%;
  background: url('../images/background/services-hero-bg.webp') ;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #fff;
}
.services_page .body_section{
  width: 100%;
  height: 100%;
  background: url('../images/background/industreis-bg.webp') ;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.services_page .services_body{
  color: #3d3d3d;    
}
.services_body_sec hr{
  margin: auto;
  border: 2px solid rgb(201, 201, 201);
  width: 50%;
}
.services_body_sec_desc{
  padding-top: 4rem;
}
.services_page .header_link{
  padding: 5px 0;
}
.services_page .header_link  a{
  color: #125c87;
  margin-bottom: 20px;
}
.services_page .header_link  a:hover{
  color: #0b4b24;
}
.services_body_sec img{
  width: 100%;
  height: auto;
}
.circle_bullet img{
  margin-top: 6px;
  margin-right: 8px;
  width: 14px;
  height: 14px;
}
.zoom_in{
  display: none;
}


.industries_white_line{
  border-bottom: 3px solid #fff;
}
/* industries page  */
.left_img_info a{
  text-transform: uppercase;
  padding: 7px 15px;
  background: #125c87;
  margin-right: 8px;
  height: 35px;
  color: #fff;
  font-size: 14px;
  border-radius: 3px;
  transition: .3s ease-in-out;
}
.d_show{
  display: none;
}
.left_img_info a:hover{
  text-decoration: none;
  background: #0b4b24;
}
.left_img_info p{
  text-transform: uppercase;
  margin-left: 10px;
  margin-top: 6px;
  font-size: 10px;
  font-weight: 600;
}
.left_img_info p span{
  color: #125c87;
}

.continue_btn,
.back_btn{
  text-transform: uppercase;
  text-decoration: none;
  background: #125c87;
  color: #fff;
  font-size: 17px;
  padding: 4px 25px;
  border: 2px solid transparent;
  border-radius: 30px;
  font-family: 'Poppins';
  transition: .3s ease-in-out;
}
.back_btn{  
  padding: 3px 30px;
}
.continue_btn:hover,
.back_btn:hover{
    border: 2px solid #125c87;
    color: #125c87;
    background: #fff;
    font-size: 17px;
}

.page_links .arrow{  
  padding: 3px 12px !important;
}
.arrow img{
  width: 9px;
  height: auto;
}

.page_links .page_no a:not(:last-child){
  margin-right: 4px;
}
 .page_links a:hover{
  background: linear-gradient(to right, #0f8034 0%, #093c1f 100%);
  color: #fff;
}

._arrow:hover .left_arrow{
  display: none;
}
.arrow_{
  margin-left: 1.8rem;
}

.arrow_:hover .right_arrow{
  display: none;
}

/* News events continue page  */

.body_description img{
  width: 100%;
  height: auto;
}




.et01 .hero_area {
  width: 100%;
  
  background: url('../images/background/Shielding&Advanced.webp') ;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #fff;
}
.quicklink .hero_area {
  width: 100%;
  
  background: url('../images/background/QucikLink.webp') ;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #fff;
}
.et03 .hero_area {
  width: 100%;
  background: url('../images/background/networkmonitoring.webp') ;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #fff;
}
.ee01 .hero_area {
  width: 100%;
  background: url('../images/background/custom_lab_2.webp') ;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #fff;
}
.training .hero_area {
  width: 100%;
  background: url('../images/background/training.webp') ;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #fff;
}
.et02 .hero_area {
  width: 100%;
  background: url('../images/background/analyticsolution.webp') ;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #fff;
}
.underconstruction .hero_area{
  width: 100%;
  background: url('../images/background/UnderConstruction.webp') ;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #fff;
  margin-top: -40px;
}
.underconstruction .header-1{
  color: #dfedfc;
  padding: 13vw;
}
.portfollio_header_img{
  margin-top: 30px;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center;
}
.portfollio_header_img img{
  width: 15%;
  height: 15%;
}
.ee01 ._line
{
  margin: auto;
  margin-top: 8px;
    border: 2px solid #c9c9c9;
    width: 10%;
}
.training ._line
{
  margin: auto;
    border: 2px solid #c9c9c9;
    width: 10%;
}
.et02 ._line
{
  margin: auto;
  margin-top: 8px;
    border: 2px solid #c9c9c9;
    width: 10%;
}
.et01 ._line
{
  margin: auto;
  margin-top: 8px;
    border: 2px solid #c9c9c9;
    width: 10%;
}
.quicklink ._line
{
  margin: auto;
  margin-top: 8px;
    border: 2px solid #c9c9c9;
    width: 10%;
}
.et03 ._line
{
  margin: auto;
  margin-top: 8px;
    border: 2px solid #c9c9c9;
    width: 10%;
}
.et01 .header-2{
  padding-top: 15px;
  margin: auto;
}
.quicklink .header-2{
  padding-top: 15px;
  margin: auto;
}
.et03 .header-2{
  padding-top: 15px;
  margin: auto;
}
.et02 .header-2{
  padding-top: 15px;
  margin: auto;
}
.ee01 .header-2{
  padding-top: 15px;
  margin: auto;
}
.training .header-2{
  padding-top: 15px;
  margin: auto;
}
.ee01 .header-3{
  font-size: 38px !important;
  font-weight: 600 !important;
  margin: auto;
  padding-top: 5px;
  margin-bottom: 20px;
  text-align:center;
}
.training .header-3{
  font-size: 38px !important;
  font-weight: 600 !important;
  margin: auto;
  padding-top: 5px;
  margin-bottom: 20px;
  text-align:center;
}
.et01 .header-3{
  
  font-size: 38px !important;
  font-weight: 600 !important;
  margin: auto;
  padding-top: 5px;
  margin-bottom: 20px;
  text-align:center;
}
.quicklink .header-3{
  
  font-size: 38px !important;
  font-weight: 600 !important;
  margin: auto;
  padding-top: 5px;
  margin-bottom: 20px;
  text-align:center;
}
.et02 .header-3{
  font-size: 38px !important;
  font-weight: 600 !important;
  margin: auto;
  padding-top: 5px;
  margin-bottom: 20px;
  text-align:center;
}
.et03 .header-3{
  font-size: 38px !important;
  font-weight: 600 !important;
  margin: auto;
  padding-top: 5px;
  margin-bottom: 20px;
  text-align:center;
}
.et01 .body_section{
  padding-top: 2px;
}
.quicklink .body_section{
  padding-top: 2px;
}
.et02 .body_section{
  padding-top: 2px;
}
.et03 .body_section{
  padding-top: 2px;
}
.ee01 .body_section{
  padding-top: 2px;
}
.training .body_section{
  padding-top: 2px;
}
.et01 .description{
  padding: 58px;
  padding-top: 0;
}
.quicklink .description{
  text-align: center;
  padding: 0px;
  padding-top: 0;
}
.et03 .description{
  padding: 58px;
  padding-top: 0;
}
.ee01 .description{
  padding: 58px;
  padding-bottom: 0;
  padding-top: 0;
}
.training .description{
  padding: 58px;
  padding-top: 0;
  padding-bottom: 7px;
}
.et02 .description{
  padding: 58px;
  padding-bottom: 0;
  padding-top: 0;
}
.ee01 p{
  margin-top: 0;
}
.training p{
  margin-top: 0;
}
.et02 p{
  margin-top: 0;
}
.ai_section .header-2{
  font-size: 44px;
}
.ai_section ._line
{
  margin: auto;
  margin-top: 0px;
    border: 2px solid #c9c9c9;
    width: 65%;
}
.ee01 ul{
  list-style-type: square;
  
}
.training ul{
  list-style-type: square;
  
}
.et02 ul{
  list-style-type: square;
  
}
.ee01_list{
  
  margin-top: 1rem;
  margin-left: 6rem;
}
.training_list{
  
  margin-top: 1rem;
  margin-left: 6rem;
}
.et02_list{
  
  margin-top: 1rem;
  margin-left: 6rem;
}
.ee01_list h1{
  font-size: 18px;
  font: bold;
  color: rgb(66, 124, 190);
}
.training_list h1{
  font-size: 18px;
  font: bold;
  color: rgb(66, 124, 190);
}
.et02_list h1{
  font-size: 18px;
  font: bold;
  color: rgb(66, 124, 190);
}
.ee01 .portfollio_hero_title{
  
  background-color:rgb(126, 231, 255,0);
}
.training .hero_title h1{
  
  text-shadow: 1px 1px #000000;
  background-color:rgb(126, 231, 255,0);
}
.et02 .portfollio_hero_title{
  
  color: #022130;
  background-color:rgb(126, 231, 255,0);
}
.et03 .portfollio_hero_title{
  
  color: #01191f;
  background-color:rgb(126, 231, 255,0);
}



/* customer page  */
.customer_page{
  width: 100%;
  background: url('../images/background/customer-body-bg.webp') ;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.client_logo{
  margin-top: 40px;
  text-align: center;
}
 .description{
  
  padding: 0;
}
.affiliation_logo_mobile{
  padding: 0 10px;
}
.affiliation_mobile{
  display: none !important;
}
.temp_logo_1{
  display: none;
}
.customer_page hr {
  border: 1px solid #E1E1E1;
  width: 100%;
}

.client_logo img,.aff1{ 
  width: 120px;
  height: 120px;
}
.client_logo .stc{
  margin-top: 1.5rem;
  height: 80px !important;
}

.aff2{
  width: 140px;
  height: 125px;
}
.aff3{
  margin-top: 1.8rem;
  width: 170px;
  height: 70px !important;
}
.aff4{
  margin-top: 20px;
  width: 150px;
  height: 70px !important;
}
.affiliation_logo_second{
  margin-left: -20px;
}

/* error page  */
.error_page{
  padding-top: 6rem;
  height: 60vh;
  color: #3d3d3d;
}
.error_page h1{
  font-size: 42px;
}


.description  p{
  font-size: 14px;
  line-height: 1.8;
}
.partners_sec .description  p{
  font-size: 16px;
  line-height: 1.8;
}
.mb-2rem{
  margin-bottom: 2rem;
}
.mt-2rem{
  margin-top: 2rem;
}
.services_page .mt-2rem {
  margin-top: 1rem;
}

.mb-4rem{
  margin-bottom: 4rem;
}
.mt-4rem{
  margin-top: 4rem;
}
.mt-4rem-ai{
  margin: auto;
  margin-top: 138px;
  
}
.ml-2rem{
  margin-left: 2rem;
}
.mr-2rem{
  margin-right: 2rem;
}
.pb-2rem{
  padding-bottom: 2rem;
}
.pt-2rem{
  padding-top: 2rem;
}
.et01 .pt-2rem{
  padding-top: 1rem !important;
}
.quicklink .pt-2rem{
  padding-top: 1rem !important;
}
.et02 .pt-2rem{
  padding-top: 1rem !important;
}
.et03 .pt-2rem{
  padding-top: 1rem !important;
}
.ee01 .pt-2rem{
  padding-top: 1rem !important;
}
.partners_sec .pt-2rem{
  padding-top: 0rem;
}
.pl-2rem{
  padding-left: 2rem;
}
.pr-2rem{
  padding-right: 2rem;
}
.pl-4rem{
  padding-left: 4rem;
}
.pr-4rem{
  padding-right: 4rem;
}
.pb-4rem{
  padding-bottom: 4rem;
}
.partners_sec .pb-4rem{
  padding-bottom: 0rem;
}
.pt-4rem{
  padding-top: 4rem;
}
.p-2rem{
  padding: 2rem !important;
}



.flex--wrap{
  flex-wrap: wrap;
}
.flex--wrap .description{
  flex: 15%;  
  margin-top: 1.2rem;
}
.flex--wrap .description p{
  width: 100%;
}
.flex--wrap .description:not(:last-child){
  margin-right: 2%;
}