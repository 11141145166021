/***
1. Nav Menu Section CSS

***/
.navspace{
  margin-top: 120px;
}
  a{
    text-decoration: none;
  }
  body{
    margin: 0;
    padding: 0;
  }
  
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
  /* 1. Nav Menu Section CSS */
  .mainmenu{
    z-index: 555;
  }
  .navbar{
    width: 100%;
    height: 90px;
    font-family: 'Oswald', sans-serif;
    display: flex;
    /* position: fixed; */
    background: #fff;
    z-index: 888;
    justify-content: space-between;
    padding-right: 5rem;
  }
  .mt-4rem__{
    margin-top: 90px;
  }
  
  .navbar .left{    
    display: flex;
    align-items: center;
  }
    
  .navbar .right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 6rem;
  }
  
  .navbar .right .links{
    max-height: 115px;
  }
  .navbar .right .links a{
    text-transform: uppercase;
    color: #0b3c58;
    font-size: 16px;
    margin-right: 10px;
    padding: 36px 10px;
    text-transform: uppercase;	
    transition: .3s ease-in-out;
  }
  .navbar .right .toggleNav{
    background: transparent;
    color: #0b3c58;
    border: none;
    font-size: 2rem;
    margin-right: 10px;
    display: none;
    outline: none;
  }

  /* mobile nav bar  */
  .toggleNav img{
    width: 25px;
    height: 20px;
  }
  #hidden{
    display: flex;
    background: rgba(241,241,241, .95);
    width: 100vw;
    flex-direction: column;
    transition: .33 ease-in-out;
    margin-top: -1px;
  }
  #notHidden{
    display: none;
  }
  .mobile_nav .mobile_dropdown_link{
    display: none;
  }
  .mobile_nav a{
    color: #3d3d3d;
    font-weight: 600;
  }
  .mobile_common_links{
    font-size: 18px;
    padding: 10px 0;
    padding-left: 1rem;
  }
  .mobile_dropdown_link .mobile_solution_dropdown,
  .mobile_dropdown_link a{
    font-size: 16px ;
    padding: 7px 0;
    padding-left: .5rem;
  }
  .mobile_nav .pb_0{
    padding-bottom: 0 !important;
  }
  .mobile_solution_link a{
    font-size: 14px;
    padding: 7px 0;
    padding-left: .5rem;
  }
  .mobile_nav .mobile_common_links:hover .mobile_dropdown_link{
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    color: #fff;
  }
  .mobile_solution_link{
    display: none;
  }
  .mobile_solution_dropdown:hover .mobile_solution_link{
    display: flex;
    padding-left: 10px;
    flex-direction: column;
  }
  #hidden .dropdown_bottom_part{
    height: 100vh;
    color: rgba(255,255,255, .1) !important;
    opacity: 0 !important;
  }

  /* .navbar .right .links .main_menu:hover */
  .links .solution{
    position: relative;
  }
  .dropdown_link{
    position: absolute;
    top: 90px;
    transition: .3s ease-in-out;
    transition: .4s ease-in-out;
    border-top: 5px solid #0f8034;
    display: flex;
    display: none;
  }
  .about_menu{
    margin-left: 8px;
  }
  .services_menu{
    margin-left: 16rem;
  }
  .resources_menu{
    margin-left: 31.7rem;
  }
  .event_menu{
    margin-left: 37.9rem;
  }
  .common_link:hover .dropdown_link{    
    display: flex;
    transition: .4s ease-in-out;
  }
  .dropdown_link a{
    width: 180px;
    padding: 8px 20px !important;
    margin: 0 !important;
    color: #fff !important;
    color: #fff !important;
    font-weight:  100 !important;    
    font-family: 'Poppins', sans-serif;
    font-size: 14px !important;
    transition: .3s ease-in-out;
  }

  .about_link .dropdown_link{
    background:  url('../images/background/about-link.webp');
  }
  .services_link .dropdown_link{
    background:  url('../images/background/services-link.webp');
  }
  .resources_link .dropdown_link{
    background:  url('../images/background/resources-link.webp');
  }
  .news_event_link .dropdown_link{
    background:  url('../images/background/news-events-link.webp');
  }

  .dropdown_link a:hover{
    background: #2e393f;
    text-decoration: none;
    transition: .3s ease-in-out;
  }
 
.solution .solution_dropdown{
  position: absolute;
  display: flex;
  flex-direction: row;
  padding: 0 1px;
    top: 93px;
    right: -32.9rem;
    transition: .3s ease-in-out;
    transition: .4s ease-in-out;
    background: transparent;
    border-top: 5px solid #0f8034;
    display: none;
}
.solution_dropdown .single_list{
  margin-right: -1px;
}
.link_line{
  width: 1px;
  margin-top: -5px;
  margin-left: -2px;
  height: 6.905rem;
  background: #afafaf;
}
.line-left1{
  margin-left: 0;
}
.line-left4{
  width: 2px;
}
.links .solution:hover .solution_dropdown{    
  display: flex !important;
}
.solution span{
  font-size: 10px !important;
}
.single_list img{
  margin-left: -1px;
}
.single_list .bottom_dropdown{
  background-repeat: no-repeat;
  width: 100%;
  height: 110px;
  margin-top: -5px;
  padding: 15px 3px;
}

.single_list .bottom_dropdown_bg1{
  background: url('../images/background/bottom-dropdown1.webp');
}
.single_list .bottom_dropdown_bg2{
  background: url('../images/background/bottom-dropdown2.webp');
}
.single_list .bottom_dropdown_bg3{
  background: url('../images/background/bottom-dropdown3.webp');
}
.single_list .bottom_dropdown_bg4{
  margin-left: -1px;
  background: url('../images/background/bottom-dropdown4.webp');
  width: 192px;
}
.single_list .bottom_dropdown_bg5{
  background: url('../images/background/bottom-dropdown5.webp') ;
}
.single_list .bottom_dropdown_bg6{
  background: url('../images/background/bottom-dropdown6.webp') ;
}
.single_list a{
  padding: 0 !important; 
  margin: 0 !important;
}
.single_list .bottom_dropdown p{
  font-size: 12.5px;
  text-transform: initial;
  font-weight: 580;
  color: #000;
  color: #000;
}
.single_list .bottom_dropdown .white_arrow{
  display: none;
}
.single_list .bottom_dropdown p img{
  width: auto;
  height: 9px;
  margin-top: 4px;
  margin-right: 4px;
  position: relative;
}
.single_list .bottom_dropdown p span{
  margin-right: 5px;
}
.solution:hover .bottom_dropdown{
    color: #fff !important;
}
.single_list a:hover .bottom_dropdown{
  color: #fff !important;
}
.single_list a:hover .bottom_dropdown p{
  color: #fff !important;
  font-weight: 400;
  font-size: 12px;
}
.single_list a:hover .bottom_dropdown .black_arrow{
  display: none;
}
.single_list a:hover .bottom_dropdown .white_arrow{
  display: inline;
}
.single_list a:hover .bottom_dropdown{
  background-repeat: no-repeat;
}

.single_list .dropdown_link_1:hover .bottom_dropdown{
  background: url('../images/background/dropdown_hover1.webp') ;
}
.single_list .dropdown_link_2:hover .bottom_dropdown{
  background: url('../images/background/dropdown_hover2.webp') ;
}
.single_list .dropdown_link_3:hover .bottom_dropdown{
  background: url('../images/background/dropdown_hover3.webp') ;
}
.single_list .dropdown_link_4:hover .bottom_dropdown{
  background: url('../images/background/dropdown_hover4.webp') ;
}
.single_list .dropdown_link_5:hover .bottom_dropdown{
  background: url('../images/background/dropdown_hover5.webp') ;
}
.single_list .dropdown_link_6:hover .bottom_dropdown{
  background: url('../images/background/dropdown_hover6.webp') ;
}

.dropdown_link .eBOOK{
  text-transform: initial !important;
}

 
  /* IndustriesCard css */
  .i_single_card{
    width: 10rem;
    height:13rem;
    background: #f1f1f1;
    padding: 8px;
    border: 3px solid #fff;
    border-radius: 20px;
    box-shadow: 1px 5px 30px #888888;
    transition: .4s ease-in-out;
    margin-bottom: 10px;
  }
  .i_single_card:hover{
    box-shadow: none;
    text-decoration: none;
  }
  .i_single_card:hover a{
    text-decoration: none;
  }
  .i_single_card img{
    width: 100%;
    height: 9rem;
    margin-bottom: 0px;
  }
  .i_single_card p{
    text-align: center;
    padding: 0;
    font-size: 15px;
    width: 100%
  }
  .i_text{
    color: #3d3d3d;
  }


  /* Footer component css*/
.footer_area{
  width: 100%;
  background: #001a2b;
  padding: 3rem 10px;
}

.footer_menu a{
  color: #8fa8ca;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  transition: .3s ease-in-out;
}
.footer_menu a:hover{
  text-decoration: none;
  color: #fff;
}
.footer_menu .footer_item a{
  margin-left: 15px !important;
  text-transform:capitalize !important;
}
.dropdown_nav  a{
  margin-top: 10px;
  color: #9bafcc !important;
  font-size: 12px !important;
  font-weight: 400;
}
.dropdown_nav a:hover{
  color: #fff !important;
}

.footer_line{
  margin: auto;
  width: 90%;
  border: 1px solid #8fa8ca;
  margin-top: 20px;
}
.footer_area .end_sec{
  padding-top: 10px;
}
.end_sec .search{
  width: 100%;
}
.end_sec .search form input{
  width: 30%;
  position: relative;
  background: #e0e0e0;
  border: none;
  border-radius: 25px;
  padding: 4px 15px;
  outline: none;
}
.end_sec .search img{
  margin-top: 4px;
  width: 14px;
}
.end_sec .search form button{
  position: absolute;
  margin-left: -32px;
  border: none;
  background: #e0e0e0;
  border-radius: 25px;
  padding: 4px 10px;
  outline: none;
  font-size: 14px;
  text-transform: uppercase;
  box-shadow: 1px 1px 15px #888888;
  cursor: pointer;
  transition: .3s ease-in-out;
}
.search form button:hover{
  box-shadow:none;
}
.end_sec .social_area p{
  text-transform: uppercase;
  color: #fff;
  margin-top: 5px;
}
.end_sec .social_area a{
  height: fit-content;
  width: 30px;
  padding: 4px 0;
  transition: .3s ease-in-out;
}
.end_sec .social_area a:hover{
  box-shadow: 0px 0px 3px #888888;
  
}

.dropdown_nav .link_header{
  font-size: 16px !important;
}


/* Contact area css Start  */


._contact_text .contact_text{
  padding-top: 1rem;
  padding-bottom: 10px;
  text-align: center;
  font-size: 32px;
}
 .contact_text p{
   font-size: 20px !important;
 }
.contact_btn{
  text-align: center; 
}
.contact_btn a{
  padding: 5px 20px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
  background: #125c87;
  border: 2px solid transparent;
  border-radius: 40px;
  color: #fff;
  transition: .3s ease-in-out;
}
.contact_btn a:hover{
  border: 2px solid #125c87;
  color: #125c87;
  background: #fff;
  text-decoration: none;
}



/* slider area start  */


@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 7))}
}


.__slider {
  height: 350px;
	margin: auto;
	overflow:hidden;
	position: relative;
  width: 100%;
}
.__slider::before,
.__slider::after {
			content: "";
		height: 350px;
		position: absolute;
		width: 350px;
		z-index: 2;
	}
	
  .__slider::after {
		right: 0;
		top: 0;
		transform: rotateZ(180deg);
	}

  .__slider::before {
		left: 0;
		top: 0;
	}
	
  .__slider .__slide-track {
		animation: scroll 40s linear infinite;
		display: flex;
		width: calc(250px * 14);
	}	
  .__slider .__slide {
    margin: 0 3rem;
	}
  .__slider .__slide p{
    font-family: 'Oswald', sans-serif;
    line-height: 1.5;
    margin-top: 20px;
    text-align: center;
  }

  
  