@media screen and (max-width: 1230px) {
  .navbar {
    padding-right: 3rem;
  }
  .navbar .right {
    padding-right: 4rem;
  }
}
@media screen and (min-width: 1200px) {
  .hero_title .title {
    padding-top: 120px !important;
    padding-bottom: 120px !important;
  }
  .partners_sec .hero_title .title {
    padding-top: 60px !important;
  }
}
@media screen and (max-width: 1190px) {
  .navbar {
    padding-right: 1rem;
  }
  .navbar .right {
    padding-right: 0rem;
  }
}
@media screen and (max-width: 1120px) {
  .navbar {
    padding-right: 0;
  }
  .navbar .right .links a {
    font-size: 14px;
    margin-right: 8px;
    padding: 36px 8px;
  }
}
@media screen and (max-width: 1199px) {
  .temp_logo {
    width: 0;
  }
  .content-sec .partical_card_item .description {
    margin: 0 !important;
  }
}
/* On screens that are 992px wide or less, */
@media screen and (max-width: 992px) {
  .bottom {
    flex-direction: column !important;
  }
  /* resources_page  */
  .card_bottom a {
    font-size: 18px;
    padding: 8px 2rem;
  }

  .temp_logo_1 {
    display: block;
  }

  .partical_card_d-none {
    display: flex;
  }
  .peruse_sec {
    background: url("../images/background/Screen.webp");
    height: 75rem;
  }
  .partical_card_item {
    display: block;
    margin: auto;
    padding: 20px !important;
  }
  .peruse_sec .right {
    margin-top: 2rem;
  }
  .peruse_sec .title h1 {
    font-size: 28px;
  }
}
@media screen and (max-width: 991px) {
  .hide_logo_1 {
    display: none;
  }
  .temp_logo_1 {
    display: block;
  }
  .affiliation_desktop {
    display: none;
  }
  .affiliation_mobile {
    display: flex !important;
  }

  .portfolio_sec a img {
    width: 210px;
  }
}

/* navbar for mobile or 919px wide or less screen */
@media screen and (max-width: 919px) {
  .navbar .right {
    padding-right: 3rem;
  }
  .navbar .right .toggleNav {
    display: flex;
  }
  .navbar .right .links {
    display: none;
  }
}

/* On screens that are 992px wide or less, */
/* iphoneX rotate */
@media only screen and (max-width: 825px) {
  /* footer menu  */
  .footer_nav .footer_menu {
    padding-right: 10px;
    padding-top: 20px;
  }
  .partner_logo {
    margin-top: 0;
    padding-top: 0;
  }
  .partner_top_logo {
    display: flex;
    flex-direction: column;
  }
  .partner_top_logo .col-md-1,
  .partner_logo_bottom .col-md-1 {
    display: none;
  }
  .partner_logo .partner_single_logo {
    margin: 0 !important;
    padding: 0 !important;
    margin: auto !important;
    text-align: center !important;
    margin-bottom: 25px !important;
  }
  .partner_logo .partner_double_loge {
    justify-content: center;
  }
  .partners_sec {
    padding-bottom: 2rem !important;
  }
  .partner_logo .aws {
    width: 110px !important;
    height: auto;
  }
  .partner_logo .micro {
    width: 170px;
    height: auto;
  }
  .partner_logo .ampex {
    width: 190px;
    height: auto;
  }
  .partner_logo .binary {
    width: 100px;
    height: auto;
  }
  .partner_logo .ibm {
    margin-left: 10px;
    width: 180px;
    height: auto;
  }
  .partner_logo .partnerLogo {
    width: 70px;
    height: auto;
  }
}

/* On screens that are 768px wide or less, */
@media only screen and (max-width: 768px) {
  .header-1 {
    font-size: 42px !important;
  }
  #home_page ._hero-area .header-2 {
    font-size: 34px;
  }
  .header-2 {
    font-size: 32px !important;
    font-weight: 600;
  }
  .header-3 {
    font-size: 22px !important;
  }

  .hero_title .title {
    padding-top: 72px !important;
    padding-bottom: 72px !important;
  }
  .navbar .main_logo {
    width: 160px !important;
  }
  .cantact_page .submit-btn {
    margin-bottom: 20px;
  }
  .cantact_page .left {
    padding-bottom: 20px;
  }
  /* footer area  */
  .footer_area .social_area {
    justify-content: center !important;
    padding-top: 20px;
  }
  .peruse_sec .particals {
    display: none !important;
  }
  .peruse_sec .particals_phone {
    display: flex !important;
  }

  /* home page  */
  #home_page ._hero-area {
    background: url("../images/background/home-hero-tab-bg.webp") no-repeat;
    height: 65vh;
  }
  #home_page ._hero-area .hero-area {
    padding-top: 20px;
  }
  #home_page ._hero-area .hero-area div {
    padding-top: 0 !important;
  }

  /* services Page  */
  .services_tag_link .header_link {
    text-align: center !important;
    padding: 0 !important;
    padding-bottom: 0.8rem !important;
  }
  .services_tag_link {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
  .fade_item {
    display: none;
  }
  .zoom_in {
    display: block;
  }

  /* slider  */
  .__slider .__slide {
    margin: 0 1.5rem;
  }
  .__slider .__slide img {
    width: 130px;
    height: 130px;
  }
  .__slider .stc img {
    width: 100px;
    height: 90px;
    margin-bottom: 0;
  }
}

/* On screens that are 766px wide or less, */
@media screen and (max-width: 766px) {
  .partner_double_loge {
    justify-content: center !important;
  }
  ._hero-area .hero-area {
    padding-left: 10px !important;
  }
  ._hero-area .hero_description {
    margin-top: 2rem !important;
  }

  .partners_sec {
    background: url("../images/background/partners-mobile-bg.webp");
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-top: 2rem;
  }
  .ai_section .row .col-md-7 {
    text-align: center !important;
  }
  .client_logo .description {
    margin: 1.5rem;
    padding: 0;
  }
  .affiliation_mobile.description {
    margin: 0 !important;
    padding: 0;
  }
  .bottom {
    flex-direction: row !important;
  }
  .filter_area select,
  .filter_area input {
    margin-bottom: 15px;
  }

  .peruse_sec {
    height: 62rem;
  }

  .ai_section .col-md-7 .header-2 {
    margin-top: 0;
  }
  /* services page  */
  .services_img_phone {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 678px) {
  .navbar .main_logo {
    width: 140px !important;
  }
  .hero_title .title {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }

  .industries_card {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .industries_card div {
    margin: 10px auto;
    padding: 5px;
  }

  .i_single_card {
    width: 12rem;
    height: 14.8rem;
  }
  .i_single_card img {
    height: 11rem;
  }
}
@media screen and (max-width: 600px) {
  #home_page ._hero-area .hero_description p {
    margin-top: 4rem;
    width: 80%;
    line-height: 1.5;
  }
  .hero_title .title {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
  .bg-white {
    padding-left: 10px;
    padding-right: 10px;
  }
  .belief_text p {
    font-size: 20px;
  }
  .services_page .header_link a {
    margin-bottom: 18px;
  }
  .peruse_sec .title h1 {
    font-size: 24px;
  }
}
@media screen and (max-width: 582px) {
}
/* On screens that are 550px wide or less, */
@media only screen and (max-width: 5578px) {
  .body_section {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

/* On screens that are 550px wide or less, */
@media only screen and (max-width: 550px) {
  .header-1 {
    font-size: 34px !important;
  }
  #home_page ._hero-area .header-2 {
    font-size: 30px;
  }
  .header-2 {
    font-size: 28px !important;
    font-weight: 600;
  }
  .header-3 {
    font-size: 20px !important;
  }
  .hero_title .title {
    padding-top: 42px !important;
    padding-bottom: 42px !important;
  }
  .cantact_page .submit-btn {
    margin-bottom: 20px;
  }
  .cantact_page .left {
    padding-bottom: 20px;
  }

  .bottom {
    flex-direction: column !important;
  }
  .end_sec .search form input {
    width: 70%;
    margin: auto;
  }
}
@media only screen and (max-width: 540px) {
  #home_page ._hero-area {
    width: 100vw;
    background: url("../images/background/home-hero-mobile-bg.webp") no-repeat
      center;
    padding-top: 2rem;
    background-size: cover;
    background-size: 100% 100%;
  }
}
@media screen and (max-width: 480px) {
  .d_none {
    display: none;
  }
  .d_show {
    display: flex;
  }
  .body_description {
    padding-top: 1rem !important;
  }
}

@media screen and (max-width: 400px) {
  .header-1 {
    font-size: 30px !important;
  }
  #home_page ._hero-area .header-2 {
    font-size: 24px;
  }
  .header-2 {
    font-size: 24px !important;
    font-weight: 600;
  }
  .header-3 {
    font-size: 18px !important;
  }
  .hero_title .title {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  /* .resources_page .button_group button {
    font-size: 15px;
  } */
  .page_links ._arrow {
    margin-right: 1rem !important;
  }
  .page_links .arrow_ {
    margin-left: 0.8rem !important;
  }
}

/* ***********************Sharif*********************************** */
@media screen and (min-width: 768px) and (max-width: 770px) {
  .quicklink .header-3 {
    font-size: 35px !important;
  }
  .mt-4rem-ai {
    margin: auto;
    margin-top: 95px;
  }
}

@media screen and (min-width: 415px) {
  .navbar .main_logo {
    width: 200px !important;
  }
  .partner_logo .micro {
    margin-left: 42px !important;
  }
  .partner_double_loge .binary {
    margin-left: 7rem !important;
  }
  .cap_msg_display {
    display: none !important;
  }
  #home_page ._hero-area .hero_description p {
    margin-top: 60px;
    font-size: 18px !important;
    width: 57%;
  }
  .service_page_mt-4rem__ {
    margin-top: 90px;
  }
  .service_page_mt-4rem__vm {
    margin-top: 160px;
  }
  .service_page_img_phone {
    margin-bottom: 4rem;
  }
  .footer_menu .none_for_laptop {
    display: none !important;
  }
}
@media screen and (max-width: 414px) {
  .ai_section img {
    width: 90%;
    height: 100%;
}
  .partner_logo .partnerLogo {
    width: 70px;
    height: 100%;
}
  .partner_logo .binary {
    width: 100px;
    height: 100%;
}
  .partner_logo .ibm {
    margin-left: 19px;
  }
  .cap_msg_display {
    font-size: 17px;
    text-align: center !important;
  }
  .pdf_display {
    display: none !important;
  }
  .services_tag_link {
    padding-top: 7vh !important;
    padding-bottom: 8vh !important;
  }
  .underconstruction .hero_area {
    width: 100%;

    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: #fff;
    margin-top: 0px;
  }
  .quicklink .header-3 {
    font-size: 18px !important;
  }
  .ai_section img {
    margin-top: -60px;
    margin-bottom: 60px;
  }
  .ai_section .col-md-7 .header-2 {
    margin-top: -105px;
  }
  .ee01 .description {
    padding: 20px;
  }
  .ee01 .header-3 {
    font-size: 38px !important;
    font-weight: 600 !important;

    margin: auto;

    padding-top: 5px;

    text-align: center;
    line-height: 1;
  }
  .et01 .description {
    padding: 20px;
  }
  .et01 .header-3 {
    font-size: 38px !important;
    font-weight: 600 !important;

    margin: auto;

    padding-top: 5px;

    text-align: center;
    line-height: 1;
  }
  .et02 .description {
    padding: 20px;
  }
  .et02 .header-3 {
    font-size: 38px !important;
    font-weight: 600 !important;

    margin: auto;
    padding-top: 5px;
    text-align: center;
    line-height: 1;
  }
  .et02_list {
    margin-top: 1rem;
    margin-left: 2rem;
  }
  .et03 .description {
    padding: 20px;
  }
  .et03 .header-3 {
    font-size: 38px !important;
    font-weight: 600 !important;
    margin: auto;
    padding-top: 5px;
    text-align: center;
    line-height: 1;
  }

  .training .description {
    padding: 20px;
    padding-bottom: 0;
    padding-top: 5px;
  }
  .training .header-3 {
    font-size: 38px !important;
    font-weight: 600 !important;
    margin: auto;
    padding-top: 5px;
    text-align: center;
    line-height: 1;
  }
  .partner_logo .micro {
    width: 170px;
    height: auto;
    margin-left: 5px;
  }
  .partner_logo .ampex {
    width: 190px;
    height: auto;
    margin-left: 5px !important;
  }
  .ee01 ul {
    padding-top: 0;
    padding-bottom: 0;
    list-style-type: square;
  }
  .ee01_list h1 {
    padding-bottom: 5px;
    font-size: 18px;
    font: bold;
    color: rgb(66, 124, 190);
  }
  .ee01_list {
    margin-top: 1rem;
    margin-left: 3rem;
  }
  .ee01_list p {
    padding-bottom: 15px;
  }
  .portfollio_desc_phone {
    margin-top: 10px !important;
  }
  .body_section_phone {
    padding: 0rem;
  }
  .portfollio_header_phone {
    font-size: 25px !important;
  }
  .portfollio_header_img {
    padding-bottom: 10px;
  }
  .portfollio_header_img img {
    width: 30%;
    height: 15%;
  }

  .service_page_mt-4rem__ {
    margin-top: 60px;
  }
  .service_page_img_phone {
    margin-top: 3rem !important;
    margin-bottom: 0rem;
  }

  .footer_menu .none_for_phone {
    display: none !important;
  }
  .services_body_sec_desc {
    padding-top: 1rem;
  }
}
